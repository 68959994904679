import type { LocationBreadcrumbsProps } from './LocationBreadcrumbs.types';

import { Button, BUTTON_TYPES, Tooltip } from '@compliance.ai/web-components';
import './_location-breadcrumbs.scss';

export const LocationBreadcrumbs = ({
  documentLocation,
  onBreadCrumbClick
}: LocationBreadcrumbsProps) => {
  return (
    <div className="location-breadcrumbs__container">
      {documentLocation.map(branch => {
        const isLastBreadCrumb =
          branch.doc_id === documentLocation[documentLocation.length - 1].doc_id;

        return (
          <span className="location-breadcrumbs__breadcrumb" key={branch.title}>
            <Button
              type={BUTTON_TYPES.LINK_CONTRAST}
              onClick={() => onBreadCrumbClick({ docId: branch.doc_id, shelfId: branch.shelf_id })}
              className="location-breadcrumbs__breadcrumb-button-wrapper"
            >
              <Tooltip
                title={branch.title}
                wrapperClassName="location-breadcrumbs__breadcrumb-button"
              >
                {branch.title}
              </Tooltip>
            </Button>
            {isLastBreadCrumb ? null : <>&nbsp;{'>'}&nbsp;</>}
          </span>
        );
      })}
    </div>
  );
};
