import type { LocationHandlers } from './useLocationHandlers.types';

import * as urlUtils from 'utils/url-utils';
import * as routes from 'constants/Routes';

import { QUERY_PARAMS as RESOURCES_QUERY_PARAMS } from 'shared/features/resources/resources.constants';

import { useNavigate } from 'react-router-dom';
import { useResourcesReduxActions } from 'shared/features/resources/hooks';

export const useLocationHandlers = (): LocationHandlers => {
  const navigate = useNavigate();
  const resourceReduxActions = useResourcesReduxActions();

  const handleLocationClick: LocationHandlers['handleLocationClick'] = ({ docId, shelfId }) => {
    resourceReduxActions.setActiveShelfId(shelfId);
    resourceReduxActions.setActiveDocId(docId || null);

    navigate(
      urlUtils.formatRedirectUrl({
        path: routes.resources,
        params: {
          [RESOURCES_QUERY_PARAMS.SHELF_SELECTED_ID]: shelfId,
          [RESOURCES_QUERY_PARAMS.CITATION_SELECTED_ID]: docId
        }
      })
    );
  };

  return {
    handleLocationClick
  };
};
