import type * as storeTypes from 'store';

import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import * as actions from '../resources.actions';

export const useResourcesReduxActions = () => {
  const dispatch = useDispatch<storeTypes.StoreDispatch>();

  const setSelectedJurisdictions = useCallback(
    (...args: Parameters<typeof actions.setSelectedJurisdictions>) => {
      return dispatch(actions.setSelectedJurisdictions(...args));
    },
    [dispatch]
  );

  const setActiveShelfId = useCallback(
    (...args: Parameters<typeof actions.setActiveShelfId>) => {
      return dispatch(actions.setActiveShelfId(...args));
    },
    [dispatch]
  );

  const setActiveDocId = useCallback(
    (...args: Parameters<typeof actions.setActiveDocId>) => {
      return dispatch(actions.setActiveDocId(...args));
    },
    [dispatch]
  );

  const fetchResources = useCallback(
    (...args: Parameters<typeof actions.fetchResources>) => {
      return dispatch(actions.fetchResources(...args));
    },
    [dispatch]
  );

  const fetchResource = useCallback(
    (docId: number) => {
      return dispatch(actions.fetchResource({ docId }));
    },
    [dispatch]
  );

  const fetchDocumentShelves = useCallback(
    (shelfId: number) => {
      return dispatch(actions.fetchDocumentShelves({ shelfId }));
    },
    [dispatch]
  );

  const fetchResourceHierarchy = useCallback(
    (docId: number) => {
      return dispatch(actions.fetchResourceHierarchy({ docId }));
    },
    [dispatch]
  );

  const fetchResourceHierarchyV2 = useCallback(
    (docId: number) => {
      return dispatch(actions.fetchResourceHierarchyV2({ docId }));
    },
    [dispatch]
  );

  const updateBreadcrumbs = useCallback(
    (shelfId?: number) => {
      return dispatch(actions.updateBreadcrumbs({ shelfId }));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      setSelectedJurisdictions,
      fetchResources,
      fetchResource,
      fetchResourceHierarchy,
      fetchDocumentShelves,
      updateBreadcrumbs,
      fetchResourceHierarchyV2,
      setActiveShelfId,
      setActiveDocId
    }),
    [
      setSelectedJurisdictions,
      fetchResources,
      fetchResource,
      fetchResourceHierarchy,
      fetchDocumentShelves,
      updateBreadcrumbs,
      fetchResourceHierarchyV2,
      setActiveShelfId,
      setActiveDocId
    ]
  );
};
