import type * as react from 'react';
import type { useSKUs } from 'utils/hooks';

import * as elements from 'common/DocumentsTable/elements';
import * as uiLib from '@compliance.ai/web-components';
import * as skusConstants from 'constants/SKUs';

export enum TABLE_KEYS {
  ID = 'id',
  DOCUMENT = 'document'
}

export enum RESULTS_COUNT_PLACEMENT {
  NEAR_SELECT_ALL = 'nearSelectAll',
  NEAR_PAGINATION = 'nearPagination'
}

export const DEFAULT_ROWS_COUNT = 20;
export const DEFAULT_OFFSET = 0;
export const DEFAULT_TABLE_SETTINGS_KEY = 'documentsTableSettings';

export const SELECTED_DOCS_MAX_COUNT = 600;

export enum SORT_BY {
  PUBLICATION_DATE = 'publication_date',
  RELEVANCE = 'relevance',
  AGENCY = 'agency_short_name',
  NEWS_SOURCE = 'news_source_name',
  CATEGORY = 'category',
  RULE_EFFECTIVE_ON = 'rule.effective_on',
  RULE_COMMENTS_CLOSE_ON = 'rule.comments_close_on'
}

export const SORT_OPTIONS: uiLib.SelectOption<SORT_BY>[] = [
  {
    value: SORT_BY.PUBLICATION_DATE,
    label: 'Publication Date'
  },
  {
    value: SORT_BY.RELEVANCE,
    label: 'Relevance'
  },
  {
    value: SORT_BY.AGENCY,
    label: 'Agency'
  },
  {
    value: SORT_BY.NEWS_SOURCE,
    label: 'News Source'
  },
  {
    value: SORT_BY.CATEGORY,
    label: 'Document Type'
  },
  {
    value: SORT_BY.RULE_EFFECTIVE_ON,
    label: 'Rule Effective On'
  },
  {
    value: SORT_BY.RULE_COMMENTS_CLOSE_ON,
    label: 'Rule Comments Close On'
  }
];

export type TogglableField = {
  key: string;
  title: string;
  sku?: string;
};

export const DOCUMENT_TOGGLEABLE_FIELDS: Record<string, TogglableField[]> = {
  'Document Data': [
    {
      key: uiLib.DOCUMENT_TOGGLEABLE_FIELD.TITLE,
      title: 'Title'
    },
    {
      key: uiLib.DOCUMENT_TOGGLEABLE_FIELD.AGENCY,
      title: 'Agency'
    },
    {
      key: uiLib.DOCUMENT_TOGGLEABLE_FIELD.AUTHOR,
      title: 'Author'
    },
    {
      key: uiLib.DOCUMENT_TOGGLEABLE_FIELD.CATEGORY,
      title: 'Doc Type'
    },
    {
      key: uiLib.DOCUMENT_TOGGLEABLE_FIELD.DOCKET_ID,
      title: 'Docket ID'
    },
    {
      key: uiLib.DOCUMENT_TOGGLEABLE_FIELD.CITATION,
      title: 'Citation'
    },
    {
      key: uiLib.DOCUMENT_TOGGLEABLE_FIELD.RELATED_DOCS,
      title: 'Related Docs'
    },
    {
      key: uiLib.DOCUMENT_TOGGLEABLE_FIELD.NEWS_SOURCE,
      title: 'News Source'
    }
  ],
  Dates: [
    {
      key: uiLib.DOCUMENT_TOGGLEABLE_FIELD.PUBLICATION_DATE,
      title: 'Publication Date'
    },
    {
      key: uiLib.DOCUMENT_TOGGLEABLE_FIELD.EFFECTIVE_ON,
      title: 'Effective Date'
    },
    {
      key: uiLib.DOCUMENT_TOGGLEABLE_FIELD.COMMENTS_CLOSE_ON,
      title: 'Comments Close Date'
    },
    {
      key: uiLib.DOCUMENT_TOGGLEABLE_FIELD.CREATED_AT,
      title: 'Added to Compliance.ai'
    }
  ],
  Tags: [
    {
      key: uiLib.DOCUMENT_TOGGLEABLE_FIELD.TOPICS,
      title: 'Topics'
    },
    {
      key: uiLib.DOCUMENT_TOGGLEABLE_FIELD.CFR,
      title: 'Related CFR'
    },
    {
      key: uiLib.DOCUMENT_TOGGLEABLE_FIELD.EITL_LABELS,
      title: 'EITL Status'
    },
    {
      key: uiLib.DOCUMENT_TOGGLEABLE_FIELD.LABELS,
      title: 'Labels'
    }
  ],
  'Enforcement Data': [
    {
      key: uiLib.DOCUMENT_TOGGLEABLE_FIELD.PENALTY,
      title: 'Penalty',
      sku: skusConstants.SKU_RESTRICTION_TYPES.ENFORCEMENT_EXPLORER
    },
    {
      key: uiLib.DOCUMENT_TOGGLEABLE_FIELD.RESPONDENT,
      title: 'Respondent',
      sku: skusConstants.SKU_RESTRICTION_TYPES.ENFORCEMENT_EXPLORER
    },
    {
      key: uiLib.DOCUMENT_TOGGLEABLE_FIELD.VIOLATION,
      title: 'Violation',
      sku: skusConstants.SKU_RESTRICTION_TYPES.ENFORCEMENT_EXPLORER
    }
  ]
};

export const COLUMNS: (
  skus: ReturnType<typeof useSKUs>
) => react.ComponentProps<typeof uiLib.Table>['columns'] = skus => [
  {
    dataKey: TABLE_KEYS.DOCUMENT,
    title: '',
    isHiddenFromColumnSettings: true,
    Component: elements.DocumentItem,
    width: uiLib.TABLE_COLUMN_WIDTH.M
  },

  ...Object.entries(DOCUMENT_TOGGLEABLE_FIELDS).flatMap(([groupName, fields]) => {
    return fields.map<uiLib.Column>(field => ({
      dataKey: field.key,
      title: field.title,
      groupName: groupName,
      isDynamic: true,
      isRestricted: Boolean(field.sku) && !skus.userHasSKU(field.sku),
      isInitiallyVisible: true,
      className: 'documents-table__cell--is-hidden'
    }));
  })
];

export const LOCAL_STORAGE_KEY = 'documentsTable';
