import { useMemo } from 'react';
import type { LocationData } from './useLocationData.types';
import type { LocationProps } from '../Location.types';

export const useLocationData = ({
  props
}: {
  props: Pick<LocationProps, 'document'>;
}): LocationData => {
  const formattedData: LocationData['formattedData'] = useMemo(() => {
    return {
      documentLocationWithShelfId: (props.document.document_location || []).map((location, i) => ({
        ...location,
        shelf_id: props.document.document_parent_shelf_ids?.[i] || null
      }))
    };
  }, [props.document.document_location, props.document.document_parent_shelf_ids]);

  return { formattedData };
};
