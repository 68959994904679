import moment from 'moment';
import {
  FILTER_KEY,
  DEFAULT_STATE,
  EXPECTED_QUERY_PARAMS,
  RESPONDENT_TYPE_OPTIONS,
  QUERY_PARAMS,
  PENALTY_OPTIONS
} from './EnforcementFilter.constants';
import queryString from 'utils/query-string';
import { enforcementExplorer } from 'constants/Routes';
import {
  formatAgencyOptions,
  formatLanguageOptions
} from 'shared/features/filters/hooks/useFilterOptions.helpers';

export const getSavedFilters = props => {
  const { enforcementFilter } = props.current_user.user.properties;

  if (enforcementFilter) {
    return {
      [FILTER_KEY.FEDERAL_SOURCE]: Array.isArray(enforcementFilter[FILTER_KEY.FEDERAL_SOURCE])
        ? enforcementFilter[FILTER_KEY.FEDERAL_SOURCE]
        : [],
      [FILTER_KEY.JURISDICTION]: Array.isArray(enforcementFilter[FILTER_KEY.JURISDICTION])
        ? enforcementFilter[FILTER_KEY.JURISDICTION]
        : [],
      [FILTER_KEY.PENALTY_AMOUNT]: enforcementFilter[FILTER_KEY.PENALTY_AMOUNT],
      [FILTER_KEY.PENALTY_LIMIT]:
        enforcementFilter[FILTER_KEY.PENALTY_LIMIT] || DEFAULT_STATE[FILTER_KEY.PENALTY_LIMIT],
      [FILTER_KEY.PUBLISHED_FROM]: formatDate(
        enforcementFilter[FILTER_KEY.PUBLISHED_FROM] || DEFAULT_STATE[FILTER_KEY.PUBLISHED_FROM]
      ),
      [FILTER_KEY.PUBLISHED_TO]: formatDate(
        enforcementFilter[FILTER_KEY.PUBLISHED_TO] || DEFAULT_STATE[FILTER_KEY.PUBLISHED_TO]
      ),
      [FILTER_KEY.HAS_UNOFFICIAL_PUBLICATION_DATE]:
        enforcementFilter[FILTER_KEY.HAS_UNOFFICIAL_PUBLICATION_DATE] === false
          ? false
          : DEFAULT_STATE[FILTER_KEY.HAS_UNOFFICIAL_PUBLICATION_DATE],
      [FILTER_KEY.VIOLATION]:
        enforcementFilter[FILTER_KEY.VIOLATION] || DEFAULT_STATE[FILTER_KEY.VIOLATION],
      [FILTER_KEY.TOPIC_ID]: Array.isArray(enforcementFilter[FILTER_KEY.TOPIC_ID])
        ? enforcementFilter[FILTER_KEY.TOPIC_ID]
        : [],
      [FILTER_KEY.RESPONDENT]:
        enforcementFilter[FILTER_KEY.RESPONDENT] || DEFAULT_STATE[FILTER_KEY.RESPONDENT],
      [FILTER_KEY.RESPONDENT_TYPE]:
        enforcementFilter[FILTER_KEY.RESPONDENT_TYPE] || DEFAULT_STATE[FILTER_KEY.RESPONDENT_TYPE],
      [FILTER_KEY.LANGUAGE]: formatDefaultLanguage(
        props.languages,
        props.current_user.user.languageId
      )
    };
  }

  return {
    [FILTER_KEY.FEDERAL_SOURCE]: DEFAULT_STATE[FILTER_KEY.FEDERAL_SOURCE],
    [FILTER_KEY.JURISDICTION]: DEFAULT_STATE[FILTER_KEY.JURISDICTION],
    [FILTER_KEY.PENALTY_AMOUNT]: DEFAULT_STATE[FILTER_KEY.PENALTY_AMOUNT],
    [FILTER_KEY.PENALTY_LIMIT]: DEFAULT_STATE[FILTER_KEY.PENALTY_LIMIT],
    [FILTER_KEY.PUBLISHED_FROM]: formatDate(DEFAULT_STATE[FILTER_KEY.PUBLISHED_FROM]),
    [FILTER_KEY.PUBLISHED_TO]: formatDate(DEFAULT_STATE[FILTER_KEY.PUBLISHED_TO]),
    [FILTER_KEY.HAS_UNOFFICIAL_PUBLICATION_DATE]:
      DEFAULT_STATE[FILTER_KEY.HAS_UNOFFICIAL_PUBLICATION_DATE],
    [FILTER_KEY.VIOLATION]: DEFAULT_STATE[FILTER_KEY.VIOLATION],
    [FILTER_KEY.TOPIC_ID]: DEFAULT_STATE[FILTER_KEY.TOPIC_ID],
    [FILTER_KEY.RESPONDENT]: DEFAULT_STATE[FILTER_KEY.RESPONDENT],
    [FILTER_KEY.RESPONDENT_TYPE]: DEFAULT_STATE[FILTER_KEY.RESPONDENT_TYPE],
    [FILTER_KEY.LANGUAGE]: formatDefaultLanguage(
      props.languages,
      props.current_user.user.languageId
    )
  };
};

export const shouldGetFiltersFromQueryParams = ({ location }) => {
  return Boolean(
    Object.keys(queryString.parse(location.search)).find(key => EXPECTED_QUERY_PARAMS.includes(key))
  );
};

export const byQueryParam = queryParam => option => {
  if (Array.isArray(queryParam)) {
    return queryParam.includes(option.value.toString());
  }
  return queryParam === option.value.toString();
};

export const getFiltersFromQueryParams = ({
  location,
  agencies: reduxAgencies,
  jurisdictions,
  sources: reduxSources,
  languages: reduxLanguages,
  concepts: reduxConcepts
}) => {
  const parsedQuery = queryString.parse(location.search);

  const filterFromQueryParams = { ...DEFAULT_STATE };

  if (parsedQuery[QUERY_PARAMS.AGENCY]) {
    filterFromQueryParams[FILTER_KEY.FEDERAL_SOURCE] = formatAgencyOptions(
      reduxAgencies.items
    ).filter(byQueryParam(parsedQuery[QUERY_PARAMS.AGENCY]));
  }

  if (parsedQuery[QUERY_PARAMS.AGENCY_ID]) {
    filterFromQueryParams[FILTER_KEY.FEDERAL_SOURCE] = formatAgencyOptions(reduxAgencies).filter(
      byQueryParam(parsedQuery[QUERY_PARAMS.AGENCY_ID])
    );
  }

  if (parsedQuery[QUERY_PARAMS.CONCEPT_ID]) {
    filterFromQueryParams[FILTER_KEY.CONCEPTS] = reduxConcepts.filter(
      byQueryParam(parsedQuery[QUERY_PARAMS.CONCEPT_ID])
    );
  }

  if (parsedQuery[QUERY_PARAMS.JURISDICTION]) {
    filterFromQueryParams[FILTER_KEY.JURISDICTION] = formatJurisdictionOptions(
      jurisdictions
    ).filter(byQueryParam(parsedQuery[QUERY_PARAMS.JURISDICTION]));
  }

  if (parsedQuery[QUERY_PARAMS.TOPIC_ID]) {
    filterFromQueryParams[FILTER_KEY.TOPIC_ID] = formatTopicOptions(reduxSources).filter(
      byQueryParam(parsedQuery[QUERY_PARAMS.TOPIC_ID])
    );
  }

  if (parsedQuery[QUERY_PARAMS.LANGUAGE_ID]) {
    filterFromQueryParams[FILTER_KEY.LANGUAGE] = formatLanguageOptions(reduxLanguages).find(
      byQueryParam(parsedQuery[QUERY_PARAMS.LANGUAGE_ID])
    );
  }

  filterFromQueryParams[FILTER_KEY.PUBLISHED_FROM] = formatDate(
    parsedQuery[QUERY_PARAMS.PUBLISHED_FROM] || ''
  );

  filterFromQueryParams[FILTER_KEY.PUBLISHED_TO] = formatDate(
    parsedQuery[QUERY_PARAMS.PUBLISHED_TO] || ''
  );

  if (parsedQuery[QUERY_PARAMS.HAS_UNOFFICIAL_PUBLICATION_DATE] === 'false') {
    filterFromQueryParams[FILTER_KEY.HAS_UNOFFICIAL_PUBLICATION_DATE] = false;
  } else {
    filterFromQueryParams[FILTER_KEY.HAS_UNOFFICIAL_PUBLICATION_DATE] = true;
  }

  if (parsedQuery[QUERY_PARAMS.RESPONDENT]) {
    filterFromQueryParams[FILTER_KEY.RESPONDENT] = parsedQuery[QUERY_PARAMS.RESPONDENT];
  }

  if (parsedQuery[QUERY_PARAMS.HAS_ENTITY_RESPONDENT]) {
    filterFromQueryParams[FILTER_KEY.RESPONDENT_TYPE] = RESPONDENT_TYPE_OPTIONS[0];
  }

  if (parsedQuery[QUERY_PARAMS.HAS_INDIVIDUAL_RESPONDENT]) {
    filterFromQueryParams[FILTER_KEY.RESPONDENT_TYPE] = RESPONDENT_TYPE_OPTIONS[1];
  }

  filterFromQueryParams[FILTER_KEY.VIOLATION] = parsedQuery[QUERY_PARAMS.VIOLATION_QUERY];

  if (parsedQuery[QUERY_PARAMS.MONETARY_PENALTY_MIN]) {
    filterFromQueryParams[FILTER_KEY.PENALTY_LIMIT] = PENALTY_OPTIONS[0];
    filterFromQueryParams[FILTER_KEY.PENALTY_AMOUNT] =
      parsedQuery[QUERY_PARAMS.MONETARY_PENALTY_MIN];
  } else if (parsedQuery[QUERY_PARAMS.MONETARY_PENALTY_MAX]) {
    filterFromQueryParams[FILTER_KEY.PENALTY_LIMIT] = PENALTY_OPTIONS[2];
    filterFromQueryParams[FILTER_KEY.PENALTY_AMOUNT] =
      parsedQuery[QUERY_PARAMS.MONETARY_PENALTY_MAX];
  } else if (parsedQuery[QUERY_PARAMS.MONETARY_PENALTY]) {
    filterFromQueryParams[FILTER_KEY.PENALTY_LIMIT] = PENALTY_OPTIONS[1];
    filterFromQueryParams[FILTER_KEY.PENALTY_AMOUNT] = parsedQuery[QUERY_PARAMS.MONETARY_PENALTY];
  }

  return filterFromQueryParams;
};

export const formatJurisdictionOptions = jurisdictions => {
  return jurisdictions
    .filter(item => item.short_name && item.name)
    .map(item => {
      const jurisdictionLabel = `${item.name} (${item.short_name})`;
      return { label: jurisdictionLabel, value: item.short_name };
    })
    .sort((a, b) => a.label.localeCompare(b.label));
};

export const formatTopicOptions = reduxSources => {
  return reduxSources.sources.activeTopics
    .map(topic => {
      return { label: topic.name, value: topic.id };
    })
    .sort((topicA, topicB) => topicA.label.localeCompare(topicB.label));
};

export const formatDate = date => {
  if (date) {
    return moment(date);
  }
  return null;
};

export const formatDefaultLanguage = (reduxLanguages, defaultUserLanguageId) => {
  let defaultLanguage;

  if (defaultUserLanguageId) {
    defaultLanguage = formatLanguageOptions(reduxLanguages).find(
      language => language.value === defaultUserLanguageId
    );
  }

  return defaultLanguage || DEFAULT_STATE[FILTER_KEY.LANGUAGE];
};

export const shouldShowAlertView = (reduxAlerts, reduxCurrentView) => {
  const { editAlertId, viewAlertId } = reduxCurrentView;

  const currentAlertId = editAlertId || viewAlertId || null;
  const currentAlert =
    currentAlertId &&
    reduxAlerts.user_alerts &&
    reduxAlerts.user_alerts.find(alert => alert.id === currentAlertId);

  return currentAlert && currentAlert.search_args.includes(enforcementExplorer);
};
